import React, { useContext, useState } from 'react'
import './Intro.css'
import paltitaLogo from "../../assets/imgs/paltitaLogoConPunto.svg";
import star from "../../assets/imgs/star.svg";
import starRotated from "../../assets/imgs/star-rotated.svg";
import ondas from "../../assets/imgs/ondasFondo.svg";
import dot from "../../assets/imgs/dot.svg";
import textLine from "../../assets/imgs/textLine.svg";
import { useHistory } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext';
import textLineResponsive from "../../assets/imgs/textLineResponsive.svg";
import { motion } from "framer-motion";

export const OnBoarding = ({userData}) => {

  const [buttonOnClick, setButtonOnClick] = useState(false);

    let history = useHistory();

    const textLineMedia = window.matchMedia("(min-width: 0px) and (max-width: 480px)");  
  
    const { iniciarChat } = useContext( AuthContext ); //Context
  
    const {sexo, genero, edad} = userData;
  
  
    const onClickChat = async(e) => {
      setButtonOnClick(true);
      await iniciarChat(sexo, genero, edad); //Context
    }

    return (
        <>
        <div className="container">
          {/* <img className="ondas-vector" src={ondas}></img> */}
          <div className="main-content">
            <img className="star-vector" src={star} />
            <img className="star-rotated-vector" src={starRotated}></img>
    
            {/* Titulo */}
            <motion.div 
              className="text-container"
              initial={{opacity: 0, y: 20}}
              animate={{opacity: 1, y: 0}}
              transition={{duration: 0.7}}
            >
              <h1 className="onboarding-text">¡Genial!</h1>
              <p className="main-text">{'Ahora es tu turno de hacer las preguntas'}</p>
              {
                (textLineMedia.matches) ? 
                <img className="text-line" src={textLineResponsive} />
                :
                <img className="text-line" src={textLine} />
              }
            </motion.div>

            {/* Imagen */}
            <div className="img-container">
              <img 
                src={paltitaLogo}
                className="main-img"
                ></img>
            </div>

            {/* <div> */}
            {/* <Lottie options={defaultOptions}
                  height={350}
                  width={400}/>
                </div> */}
    
            {/* Boton */}
            <div className="button-container">
            <img className="dot-vector" src={dot}></img>
              <button 
                className="main-button"
                onClick={onClickChat}
                disabled={buttonOnClick}
                >
                Empecemos a chatear
              </button>
            </div>
          </div>
        </div>
        </>
    )
}
