/*
Componente    : Messages.js
Ubicacion     : src/components/Messages
Descripcion   : Componente que muestra todos los mensaje del chat
Argumentos    : messages: Mensajes del chat
                name: Usuario
Utilizado por : /src/components/Chat.js
Notas         : Si es del mismo usuario muestra el mensaje con estilo diferente al del admin
*/

import React, { useEffect, useRef } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import Message from "./Message/Message";
import "./Messages.css";

const Messages = ({ messages, name }) => {
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current &&
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [messages]);

  return (
    <div className="messages-container">
      <ScrollToBottom id="mainContainerChat" className="messages">
        {messages ? (
          messages.map((message, i) => (
            <div key={i} className="new-end-fn">
              <Message message={message} name={name} />
              <div ref={messagesEndRef}></div>
            </div>
          ))
        ) : (
          <></>
        )}
      </ScrollToBottom>
    </div>
  );
};

export default Messages;
