import React, { useEffect, useRef } from 'react'
import './Intro.css'
import paltitaLogo from "../../assets/imgs/paltitaLogoConPunto.svg";
import star from "../../assets/imgs/star.svg";
import starRotated from "../../assets/imgs/star-rotated.svg";
import dot from "../../assets/imgs/dot.svg";
import {motion} from "framer-motion";
import '@lottiefiles/lottie-player';
import Lottie from "react-lottie";
import explosion from "../../assets/Lotties/explosion.json";

export const Welcome = ({nextPage}) => {

  const handleNextStep = () => {
    nextPage();
  }
  
  const explosionDefaultOptions = {
    loop: false,
    autoplay: true, 
    animationData: explosion,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <>
        <div className="container container-background">
          {/* <img className="ondas-vector" src={ondas}></img> */}
          <div className="main-content">
            <img className="star-vector" src={star} />
            <img className="star-rotated-vector" src={starRotated}></img>
    
            {/* Titulo */}
            <div className="text-container animate__animated animate__fadeInUp">
              <p className="welcome-text">Hola! Soy Paltita</p>
              <div
                className="stars-lottie"
              >
                <Lottie
                  options={explosionDefaultOptions}
                  width={'100%'}
                  // height={'100%'}
                />
              </div>
            </div>

            {/* Imagen */}

            <motion.div
              className="img-container"
              initial={{width: 0, height: 0}}
              animate={{width: "100%", height:"50%"}}
              transition={{
                duration: 1
              }}
            >
              <img 
                src={paltitaLogo}
                className="main-img"
              ></img>
            </motion.div>
    
            {/* Boton */}
            <div className="button-container">
              <img className="dot-vector" src={dot}></img>
              <button 
                className="main-button"
                onClick={handleNextStep}
                >
                Hola Paltita!
              </button>
              
            </div>
          </div>
        </div>
        </>
    )
}
