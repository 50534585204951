import React, { useState } from 'react'
import { PopupScreen } from '../Popup/PopupScreen';
import "./Intro.css";
import star from "../../assets/imgs/star.svg";
import starRotated from "../../assets/imgs/star-rotated.svg";
import dot from "../../assets/imgs/dot.svg";
import iconoPregunta from "../../assets/imgs/icono-pregunta.svg";
import contadorPregunta1 from "../../assets/imgs/contadorPregunta1.svg";
import flechaAtras from "../../assets/imgs/flechaAtras.svg";
import { motion } from "framer-motion";

export const SexSelection = ({nextPage, lastPage, setUserData}) => {

  const [porqueButton, setPorqueButton] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);

  const [sexoSelection, setSexoSelection] = useState('');
  const [sexoSelectionWarn, setSexoSelectionWarn] = useState(false);

  const iconMedia = window.matchMedia("(min-width: 0px) and (max-width: 480px)");

  const handleNextStep = () => {

    if(sexoSelection) {
      sexUserData();
      nextPage();
    } else {
      setSexoSelectionWarn(true)
    }
  }

  const sexUserData = () => {
    setUserData({sexo: sexoSelection})
  }

  return (
    <div className="container">
      <div className="main-content">
        <img className="star-vector" src={star} />
          <img className="star-rotated-vector" src={starRotated}></img>

        {/* Titulo */}
        <div className="text-container">
          <motion.div
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.8}}
          >
            <img src={contadorPregunta1}></img>
          </motion.div>
          <motion.div 
            className="pregunta-icono-container"
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.8}}
          >
            <h1 className="main-text bold-text">¿Cuál es tu sexo? </h1>
            <div className="button-icono">
              {
                (iconMedia.matches) ?
                  <></>
                :
                <button 
                  className="icono-pregunta"
                  onClick={() => setButtonStatus(true)}
                >
                  <img src={iconoPregunta}></img>
                </button>
              }
            </div>
          </motion.div>
          <motion.div
            initial={{opacity: 0, y: 30}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.7}}
          >
            <h3 className="secondary-text">Selecciona</h3>
          </motion.div>
        </div>
        <button
        onClick={() => lastPage()}
          className="flecha-atras-vector"
        >
          <img src={flechaAtras} />
        </button>

        {/* Buttons */}
          <motion.div 
            className="buttons-container"
            initial={{opacity: 0, y: 40}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.4}}
          >
           <button 
            className={`secondary-buttons ${ sexoSelection === 'Mujer' && 'bg-orange'}`}
            onClick={() => setSexoSelection('Mujer')}
           >
               Mujer
           </button>    
           <button 
            className={`secondary-buttons ${ sexoSelection === 'Hombre' && 'bg-orange'}`}
            onClick={() => setSexoSelection('Hombre')}
           >
               Hombre
           </button>    
           <button 
            className={`secondary-buttons ${ sexoSelection === 'Intersex' && 'bg-orange'}`}
            onClick={() => setSexoSelection('Intersex')}
           >
               Intersex
           </button>
          </motion.div>
          {
            (sexoSelectionWarn) &&
            <p className="warning-text secondary-text">Selecciona una respuesta para poder ayudarte mejor</p>
          }

        {/* Boton */}
        <div className="button-container">
          <img className="dot-vector" src={dot}></img>
          <button 
            // className="main-button main-button-margin"
            className={`${ sexoSelection ? 'main-button main-button-margin' : 'transparent-button main-button-margin'}`}
            onClick={handleNextStep}
            >
            Continuar
          </button>
          <button
            className="popup-button"
            onClick={() => setPorqueButton(true)}
          >
            ¿Por Qué?
          </button>
          { 
            (buttonStatus) &&
            <PopupScreen 
              title={"¿Qué es sexo?"} 
              content={"El sexo de la persona está determinado por características físicas que nos definen como hombres o mujeres. Se enfatiza en las diferencias genitales y corporales (pene - vulva). Puede influir en las características corporales como los órganos reproductivos internos, las características sexuales secundarias (mamas, vello facial, etc.), entre otros. "} 
              popupStatus={buttonStatus} 
              setPopup={setButtonStatus}
            /> 

          }
          {
            (porqueButton) &&
            <PopupScreen 
              title={"Preguntas acerca de ti"} 
              content={<>Conocer esta información me permitirá elaborar una<br></br>respuesta más adecuada para ti</>} 
              popupStatus={buttonStatus} 
              setPopup={setPorqueButton}
            /> 
          }
        </div>
      </div>
    </div>
  )
}