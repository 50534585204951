import React from "react";
import { Redirect, Route } from "react-router";

export const PrivateRoute = ({
  privatePath,
  isToken,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      component={(props) =>
        isToken ? <Redirect to={privatePath} /> : <Component {...props} />
      }
    />
  );
};
