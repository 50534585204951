import React, { useEffect } from 'react'
import './Intro.css'
import star from "../../assets/imgs/star.svg";
import dot from "../../assets/imgs/dot.svg";
import starRotated from "../../assets/imgs/star-rotated.svg";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import explosion from "../../assets/Lotties/explosion.json";

export const NuevoGeneroPage = ({nextPage, genero}) => {
    
  useEffect(() => {
    const timer = setTimeout(() => {
        nextPage();
    }, 3000);
  
    return () => {
      clearTimeout(timer);
    }
  }, [])

  const explosionDefaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: explosion,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
    return (
        <div className="container width-adjust">
          {/* <img className="ondas-vector" src={ondas}></img> */}
          <div className="nuevo-genero-adjust">
            <img className="star-vector" src={star} />
            <img className="star-rotated-vector" src={starRotated}></img>
            <motion.h1
              initial={{opacity: 0, y: 20}}
              animate={{opacity: 1, y: 0}}
              transition={{duration: 0.7}}
              className="main-text bold-text"
            >
              Genial
            </motion.h1>
            <motion.div
              initial={{opacity: 0, y: 20}}
              animate={{opacity: 1, y: 0}}
              transition={{duration: 0.7}} 
              className="nuevo-genero-container"
            >
                <p className="nuevo-genero-text">{genero}</p>
              {/* </div> */}
                <Lottie
                  options={explosionDefaultOptions}
                  width={300}
                  height={300}
                  />

            </motion.div>
            <img className="dot-vector-intro" src={dot}></img>
          </div>
        </div>
    )
}
