import React, { useState } from "react";
import "./popup.css";
import "../Intro/Intro.css";
import star from "../../assets/imgs/star.svg";
import starRotated from "../../assets/imgs/star-rotated.svg";
import dot from "../../assets/imgs/dot.svg";
import contadorPregunta2 from "../../assets/imgs/contadorPregunta2.svg";
import estrellasPopup from "../../assets/imgs/estrellasPopup.svg";
import flechaAtras from "../../assets/imgs/flechaAtras.svg";
import { motion } from "framer-motion";

export const PopupScreen = ({
  setPopup,
  title,
  content,
  type = "info",
  initialState = { inputContent: "" },
  popupUserData,
  popupSetUserData,
  popupSetIsGenero,
  setNuevoGenero,
  set0troGeneroPopup,
}) => {
  const [values, setValues] = useState(initialState);
  const [porqueButton, setPorqueButton] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);

  const { inputContent } = values;

  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (e) => {
    if (inputContent.length >= 1) {
      e.preventDefault();
      popupSetUserData({ ...popupUserData, genero: inputContent });
      setPopup(false);
      popupSetIsGenero(true);
      setNuevoGenero(true);
    }
  };

  const buttonOnClick = () => {
    if (inputContent.length >= 1) {
      popupSetUserData({ ...popupUserData, genero: inputContent });
      setPopup(false);
      popupSetIsGenero(true);
      setNuevoGenero(true);
    }
  };

  return (
    <>
      {type === "info" && (
        <div className="popup-container">
          <div className="popup-inner">
            <img className="estrellas-popup" src={estrellasPopup}></img>
            {/* <div className="text-container"> */}
            <p className="popup-title">{title}</p>
            <p className="popup-text">{content}</p>
            {/* </div> */}
            <button className="close-button" onClick={() => setPopup(false)}>
              Ok
            </button>
          </div>
        </div>
      )}
      {type === "form" && (
        // </div>
        <div className="container container-popup-adjust">
          <img className="dot-vector" src={dot}></img>
          <div className="main-content">
            <img className="star-vector" src={star} />
            <img className="star-rotated-vector" src={starRotated}></img>
            {/* Titulo */}
            <motion.div
              className="text-container"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
            >
              <img src={contadorPregunta2}></img>
              <div className="pregunta-icono-container">
                <h1 className="main-text bold-text">Nombre de tu género</h1>
              </div>
              <h3 className="secondary-text">Escríbelo</h3>
            </motion.div>
            <button
              onClick={() => set0troGeneroPopup(false)}
              className="flecha-atras-vector"
            >
              <img src={flechaAtras} />
            </button>
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
            >
              <form onSubmit={handleSubmit}>
                <input
                  className="input-popup"
                  onChange={handleInputChange}
                  placeholder="Escribe tu género"
                  value={inputContent}
                  name="inputContent"
                  autoComplete="off"
                />
              </form>
            </motion.div>

            {/* {
                  (genderSelectionWarn) &&
                  <p className="warning-text secondary-text">Selecciona una respuesta para poder ayudarte mejor</p>
                } */}

            {/* Boton */}
            <div className="button-container">
              <button
                type="submit"
                className="main-button main-button-margin"
                onClick={buttonOnClick}
              >
                Continuar
              </button>
              <button
                className="popup-button"
                onClick={() => setPorqueButton(true)}
              >
                ¿Por Qué?
              </button>
            </div>
          </div>
          {porqueButton && (
            <PopupScreen
              title={"Preguntas acerca de ti"}
              content={
                "Esta información nos ayuda a tener la mejor respuesta para tu pregunta."
              }
              popupStatus={buttonStatus}
              setPopup={setPorqueButton}
            />
          )}
        </div>
      )}
    </>
  );
};
