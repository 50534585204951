import React, { useState } from "react";
import { topics, questions } from "./constants";
import infoIcon from "../../assets/imgs/iconoMasInfo.svg";
import backIcon from "../../assets/imgs/backLine.svg";
import "./styles.css";

const Header = ({ sendQuestion, setIsOpen }) => {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [currentView, setCurrentView] = useState(1);
  const [currentQuestions, setCurrentQuestions] = useState([]);

  const handleClose = () => {
    setPopUpOpen(false);
    setCurrentView(1);
  };

  const handleOpen = () => {
    setPopUpOpen(true);
  };

  const handleQuestions = (topicId) => {
    const topicQuestions = questions.filter((e) => e.topicId === topicId);
    setCurrentQuestions(topicQuestions);
    setCurrentView(2);
  };

  const handleSendQuestion = (question) => {
    sendQuestion(question);
    handleClose();
  };

  const handleBackToTopics = () => {
    setCurrentView(1);
  };

  return (
    <div className="header">
      <div className="header_container">
        <p className="header_option" onClick={handleOpen}>
          Preguntas Frecuentes
        </p>
      </div>
      {popUpOpen && (
        <div className="header_content">
          <div className="header_content_inner">
            <p className="header_close_button" onClick={handleClose}>
              Cerrar
            </p>
            {currentView === 1 ? (
              <div className="header_topics">
                <div className="header_back_button">
                  <span>Elige el tema de tu interés</span>
                </div>
                {topics.map((e) => (
                  <p key={e.id} onClick={() => handleQuestions(e.id)}>
                    {e.title}
                  </p>
                ))}
              </div>
            ) : (
              <div className="header_questions">
                <div
                  className="header_back_button"
                  onClick={handleBackToTopics}
                >
                  <img src={backIcon} alt="icono información" />
                  <span>Volver</span>
                </div>
                {currentQuestions.map((e, index) => (
                  <p
                    key={index}
                    onClick={() => {
                      handleSendQuestion(e.text);
                    }}
                  >
                    {e.text}
                  </p>
                ))}
              </div>
            )}
            <div className="header_info_button" onClick={() => setIsOpen(true)}>
              <img src={infoIcon} alt="icono información" />
              <span>Más información</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
