import React, { useState } from 'react'
import './Intro.css'
import { IntroImg } from '../Intro/IntroImg'
import { Welcome } from '../Intro/Welcome'
import { ContinueChat } from './ContinueChat'
import { SexSelection } from './SexSelection'
import { GenderSelection } from './GenderSelection'
import { AgeSelection } from './AgeSelection'
import { OnBoarding } from './OnBoarding'

export const Intro = () => {

    const [step, setStep] = useState(0);

    const [userData, setUserData] = useState({
        sexo: '',
        genero: '',
        edad: '',
    })
    
    const nextPage = () => {
        setStep(step + 1);
    };

    const lastPage = () => {
        setStep(step - 1);
    };

    const screenMedia = window.matchMedia("(min-width: 0px) and (max-width: 480px)");

    return (
        <div>
            {step === 0 && <IntroImg //0
                                nextPage={nextPage}
                            />}
                        
            {step === 1 && <Welcome //1
                                nextPage={nextPage}
                             />}

            {step === 2 && <ContinueChat //2 
                                nextPage={nextPage} 
                                text= {screenMedia.matches ? 
                                        <>Soy un bot dispuestx a responder<br></br>tus preguntas sobre la <br></br><span className="bold-text">Educación Sexual Integral (ESI)</span></> 
                                        : 
                                        <>Soy un bot dispuestx a responder tus preguntas sobre la <br></br><span className="bold-text">Educación Sexual Integral (ESI)</span></>
                                      }
                            />} 
            {step === 3 && <ContinueChat //3
                                nextPage={nextPage} 
                                text={screenMedia.matches ?
                                <>Antes de empezar necesito conocerte<br></br>un poco más para tener las<br></br>respuestas más adecuadas para ti.</>
                                :
                                <>Antes de empezar necesito conocerte un poco más para<br></br>tener las respuestas más adecuadas para ti.</>
                            }
                            />} 
                            
            {step === 4 && <ContinueChat //4
                                nextPage={nextPage} 
                                text={screenMedia.matches ?
                                <>Por tu seguridad esta conversación<br></br>es privada y confidencial</>
                                :
                                <>Por tu seguridad esta conversación es<br></br>privada y confidencial</>
                            }
                            />
                        } 
 
            {step === 5 && <SexSelection //5
                                nextPage={nextPage} 
                                setUserData={setUserData} 
                                userData={userData}
                                lastPage={lastPage}
                            />}

            {step === 6 && <GenderSelection //6
                                nextPage={nextPage} 
                                setUserData={setUserData} 
                                userData={userData}
                                lastPage={lastPage}
                            />}
            {step === 7 && <AgeSelection //7
                                nextPage={nextPage} 
                                setUserData={setUserData} 
                                userData={userData}
                                lastPage={lastPage}
                            />}
            {step === 8 && <OnBoarding  // 8
                                userData={userData} 
                            />} 
        </div>
    )
}
