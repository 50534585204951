import React, { useState } from 'react'
import "./Intro.css";
import star from "../../assets/imgs/star.svg";
import starRotated from "../../assets/imgs/star-rotated.svg";
import dot from "../../assets/imgs/dot.svg";
import { PopupScreen } from '../Popup/PopupScreen';
import contadorPregunta3 from "../../assets/imgs/contadorPregunta3.svg";
import flechaAtras from "../../assets/imgs/flechaAtras.svg";
import { motion } from "framer-motion";

export const AgeSelection = ({nextPage, setUserData, userData, lastPage}) => {

   const [buttonStatus, setButtonStatus] = useState(false) 

   const [edadSelection, setEdadSelection] = useState('')
   const [ageSelectionWarn, setAgeSelectionWarn] = useState(false)

   const handleNextStep = () => {
      if(edadSelection) {
         nextPage();
        edadUserData();
      } else {
         setAgeSelectionWarn(true)
      }
   }
     
   const popupValue = (value) => {(
      setButtonStatus(value)
    )}

   const edadUserData = () => {
      setUserData({...userData, edad: edadSelection})
   }

  return (
    <div className="container">
      <div className="main-content">
        <img className="star-vector" src={star} />
          <img className="star-rotated-vector" src={starRotated}></img>

        {/* Titulo */}
        <motion.div 
         className="text-container"
         initial={{opacity: 0, y: 20}}
         animate={{opacity: 1, y: 0}}
         transition={{duration: 0.7}}
        >
           <img src={contadorPregunta3}></img>
          <h1 className="main-text bold-text">¿Cuál es tu edad?</h1>
          <h3 className="secondary-text">Seleccciona</h3>
        </motion.div>
        <button
         onClick={() => lastPage()}
         className="flecha-atras-vector"
        >
          <img src={flechaAtras} />
        </button>

        {/* Botones */}
          <motion.div 
            className="age-buttons-container"
            initial={{opacity: 0, y: 40}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.4}}
          >
           <button 
            className={`age-buttons ${edadSelection === '-14' && 'bg-orange'}`}
            onClick={() => setEdadSelection('-14')}
           >
              -14
           </button>
           <button 
            className={`age-buttons ${edadSelection === '15' && 'bg-orange'}`}
            onClick={() => setEdadSelection('15')}
           >
              15
           </button>    
           <button 
            className={`age-buttons ${edadSelection === '16' && 'bg-orange'}`}
            onClick={() => setEdadSelection('16')}
           >
              16
           </button>
           <button 
            className={`age-buttons ${edadSelection === '17' && 'bg-orange'}`}
            onClick={() => setEdadSelection('17')}
           >
              17
           </button>
           <button 
            className={`age-buttons ${edadSelection === '18' && 'bg-orange'}`}
            onClick={() => setEdadSelection('18')}
           >
              18
           </button>
           <button 
            className={`age-buttons ${edadSelection === '19' && 'bg-orange'}`}
            onClick={() => setEdadSelection('19')}
           >
              19
           </button>
           <button 
            className={`age-buttons ${edadSelection === '20' && 'bg-orange'}`}
            onClick={() => setEdadSelection('20')}
           >
              20
           </button>
           <button 
            className={`age-buttons ${edadSelection === '21' && 'bg-orange'}`}
            onClick={() => setEdadSelection('21')}
           >
              21
           </button>
           <button 
            className={`age-buttons ${edadSelection === '22' && 'bg-orange'}`}
            onClick={() => setEdadSelection('22')}
           >
              22
           </button>
           <button 
            className={`age-buttons ${edadSelection === '23' && 'bg-orange'}`}
            onClick={() => setEdadSelection('23')}
           >
              23
           </button>
           <button 
            className={`age-buttons ${edadSelection === '24' && 'bg-orange'}`}
            onClick={() => setEdadSelection('24')}
           >
              24
           </button>
           <button 
            className={`age-buttons ${edadSelection === '+25' && 'bg-orange'}`}
            onClick={() => setEdadSelection('+25')}
           >
              +25
           </button>
          </motion.div>
          {
            (ageSelectionWarn) &&
            <p className="warning-text secondary-text-age">Selecciona una respuesta para poder ayudarte mejor</p>
          }
          
        {/* Boton */}
        <div className="button-container">
          <img className="dot-vector" src={dot}></img>
          <button 
            className="main-button main-button-margin"
            className={`${ edadSelection ? 'main-button main-button-margin' : 'transparent-button main-button-margin'}`}
            onClick={handleNextStep}
            >
            Continuar
          </button>
          <button
            className="popup-button"
            onClick={() => popupValue(true)}
          >
            ¿Por Qué?
          </button>
          { 
            (buttonStatus) &&
            <PopupScreen 
              title={"Preguntas acerca de ti"} 
              content={<>Conocer esta información me permitirá elaborar una<br></br>respuesta más adecuada para ti</>} 
              popupStatus={buttonStatus} 
              setPopup={setButtonStatus}
            /> 
          }
        </div>
      </div>
    </div>
  )
}