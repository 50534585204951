import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

import Chat from "../components/Chat";
import { Intro } from "../components/Intro/Intro";
import { PrivateRoute } from "./PrivateRoute";

export const AppRouter = () => {
  const { auth, verificaToken } = useContext(AuthContext);
  const { uid } = auth;

  useEffect(() => {
    const fetchData = async () => {
      await verificaToken();
    };
    fetchData();
  }, []);

  return (
    <Router>
      <div>
        <Switch>
          <PrivateRoute
            privatePath="/"
            isToken={!uid}
            path="/chat"
            component={Chat}
          />
          <PrivateRoute
            privatePath="/chat"
            isToken={uid}
            path="/"
            component={Intro}
          />
        </Switch>
      </div>
    </Router>
  );
};
