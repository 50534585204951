import React, { useEffect, useContext, useState } from "react";
import randomId from "random-id";

import "./styles.css";

import Messages from "../Messages/Messages";
import Header from "../Header";
import Input from "../Input";
import MoreInfoPage from "../MoreInfoPage";

import { SocketContext } from "../../context/SocketContext";

const Chat = () => {
  const { socket, messages } = useContext(SocketContext);
  const [isOpen, setIsOpen] = useState(false);

  const len = 15;
  const pattern = "aA0";

  useEffect(() => {
    const idroom = randomId(len, pattern);

    if (socket) {
      socket.emit("join", { name: "user", room: idroom }, (resp) => {
        socket.emit("sendMessage", "", () => {});
      });
    }
  }, [socket]);

  const sendInputMessage = (message) => {
    if (message) {
      socket.emit("sendMessage", message, () => {});
    }
  };

  return (
    <div className="outerContainer">
      <Header sendQuestion={sendInputMessage} setIsOpen={setIsOpen} />
      <div className="container-chat">
        <Messages messages={messages} name={"user"}></Messages>
        <Input sendQuestion={sendInputMessage} setIsOpen={setIsOpen} />
      </div>
      <MoreInfoPage isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default Chat;
