import React, { createContext, useCallback, useState } from "react";
import { fetchConToken, fetchSinToken } from "../helpers/fetch";

export const AuthContext = createContext();

const initialState = {
  uid: null,
  checking: true,
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(initialState);

  // Inicia chat
  const iniciarChat = async (sexo, genero, edad) => {
    //TODO, obtener token y guardar en localstorage, guardar uid en el state para controlar salida del intro
    const resp = await fetchSinToken(
      "iniciarChat",
      { sexo, genero, edad },
      "POST"
    );

    setAuth({ uid: resp.uid });

    localStorage.setItem("token", resp.token);
  };

  // Verifica token
  const verificaToken = useCallback(async () => {
    const token = localStorage.getItem("token");

    if (token) {
      //Forzando temporalment
      setAuth({ uid: token });
      return true;

      const resp = await fetchConToken("renewToken", { data: "" }, "POST");
      if (resp.ok) {
        // Usar cuando se supere el CORS
        localStorage.setItem("token", resp.token);
        setAuth({ uid: resp.uid });

        return true;
      } else {
        localStorage.removeItem("token");
        setAuth({ uid: "" });
        return false;
      }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        iniciarChat,
        verificaToken,
        auth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
