import React, { useState } from "react";
import star from "../../assets/imgs/star.svg";
import starRotated from "../../assets/imgs//star-rotated.svg";
import dot from "../../assets/imgs//dot.svg";
import dspLogo from "../../assets/imgs/dspLogo.svg";
import lineContacto from "../../assets/imgs/lineContacto.svg";

import whatsapp from "../../assets/imgs/redesSociales/whatsapp.svg";
import instagram from "../../assets/imgs/redesSociales/instagram.svg";
import facebook from "../../assets/imgs/redesSociales/facebook.svg";
import tiktok from "../../assets/imgs/redesSociales/tiktok.svg";
import twitter from "../../assets/imgs/redesSociales/twitter.svg";
import linkedin from "../../assets/imgs/redesSociales/linkedin.svg";
import Terms from "../Terms";

import "./styles.css";

const MoreInfoPage = ({ isOpen, setIsOpen }) => {
  const [terminos, setTerminos] = useState(false);

  const buttonWebPage = (url) => {
    window.open(url);
  };

  return (
    <>
      {isOpen && (
        <>
          {terminos ? (
            <Terms setTerminos={setTerminos} />
          ) : (
            <div className="container">
              <img className="star-vector" src={star} alt="icon" />
              <img
                className="star-rotated-vector"
                src={starRotated}
                alt="icon"
              />
              <button
                className="mi-salir-button"
                onClick={() => setIsOpen(false)}
              >
                Cerrar
              </button>
              <div className="more-info-container">
                <img src={dspLogo} className="more-info-img" alt="icon" />

                <div className="contacto-container">
                  <img src={lineContacto} className="contact-line" alt="icon" />
                  <p className="more-info-text">
                    Visitanos en:<br></br>
                    <br></br>
                    <span className="text-bold">
                      <button
                        className="mail-button"
                        onClick={() => window.open("https://dilosinpaltas.com")}
                      >
                        dilosinpaltas.com
                      </button>
                    </span>
                  </p>
                  <p className="more-info-text">
                    Si tienes alguna consulta escribenos a:<br></br>
                    <br></br>
                    <span className="text-bold">
                      preguntas@dilosinpaltas.com
                    </span>
                  </p>
                  <img src={lineContacto} className="contact-line" alt="icon" />
                </div>

                <div className="redes-sociales-container">
                  <p className="more-info-text">Nuestras redes</p>
                  <div className="icons-container">
                    <button
                      className="more-info-icon"
                      onClick={() =>
                        buttonWebPage(
                          "https://api.whatsapp.com/send/?phone=51946253327&text=%C2%A1Hola+Sin+Tab%C3%BAes%21&app_absent=0"
                        )
                      }
                    >
                      <img src={whatsapp} className="svg-buttons" alt="icon" />
                    </button>
                    <button
                      onClick={() =>
                        buttonWebPage(
                          "https://www.instagram.com/hablemos.sintabues/?hl=es"
                        )
                      }
                      className="more-info-icon"
                    >
                      <img src={instagram} className="svg-buttons" alt="icon" />
                    </button>
                    <button
                      onClick={() =>
                        buttonWebPage("https://www.facebook.com/sintabuesrs")
                      }
                      className="more-info-icon"
                    >
                      <img src={facebook} className="svg-buttons" alt="icon" />
                    </button>
                    <button
                      onClick={() =>
                        buttonWebPage("https://www.tiktok.com/@sintabues.pe")
                      }
                      className="more-info-icon"
                    >
                      <img src={tiktok} className="svg-buttons" alt="icon" />
                    </button>
                    <button
                      onClick={() =>
                        buttonWebPage("https://mobile.twitter.com/SinTabues_pe")
                      }
                      className="more-info-icon"
                    >
                      <img src={twitter} className="svg-buttons" alt="icon" />
                    </button>
                    <button
                      onClick={() =>
                        buttonWebPage(
                          "https://www.linkedin.com/company/sin-tab%C3%BAes/"
                        )
                      }
                      className="more-info-icon"
                    >
                      <img src={linkedin} className="svg-buttons" alt="icon" />
                    </button>
                  </div>
                </div>

                <img className="dot-vector-intro" src={dot} alt="icon" />

                <button
                  className="more-info-terminos"
                  onClick={() => setTerminos(true)}
                >
                  Terminos y Condiciones
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MoreInfoPage;
