import React, { useState } from 'react'
import "./Intro.css";
import star from "../../assets/imgs/star.svg";
import starRotated from "../../assets/imgs/star-rotated.svg";
import dot from "../../assets/imgs/dot.svg";
import iconoPregunta from "../../assets/imgs/icono-pregunta.svg";
import { PopupScreen } from '../Popup/PopupScreen';
import contadorPregunta2 from "../../assets/imgs/contadorPregunta2.svg";
import { NuevoGeneroPage } from './NuevoGeneroPage';
import flechaAtras from "../../assets/imgs/flechaAtras.svg";
import { motion } from "framer-motion";

export const GenderSelection = ({nextPage, setUserData, userData, lastPage}) => {

  const [porqueButton, setPorqueButton] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false)
  const [otroGeneroPopup, setOtroGeneroPopup] = useState(false);

  const [generoSelection, setGeneroSelection] = useState('');
  const [genderSelectionWarn, setGenderSelectionWarn] = useState(false);

  const [nuevoGenero, setNuevoGenero] = useState(false);

  const iconMedia = window.matchMedia("(min-width: 0px) and (max-width: 480px)");

  const [isGenero, setIsGenero] = useState(false);

  const handleNextStep = () => {
    if(generoSelection) {
      generoUserData();
      nextPage();
    } else {
      setGenderSelectionWarn(true)
    }
  }
  const generoUserData = () => {
    setUserData({...userData, genero: generoSelection})
  }
  const popupValue = (value) => {(
    setButtonStatus(value)
  )}
  const popupOtroGeneroValue = (value) => {(
    setOtroGeneroPopup(value)
  )}
  const generoOnClick = (genero) => {
    setIsGenero(false);
    setGeneroSelection(genero);
  }

  return (
    <div className="container">
      <div className="main-content">
        <img className="star-vector" src={star} />
          <img className="star-rotated-vector" src={starRotated}></img>
        {/* Titulo */}
        <motion.div 
          className="text-container"
          initial={{opacity: 0, y: 20}}
          animate={{opacity: 1, y: 0}}
          transition={{duration: 0.8}}
        >
        <img src={contadorPregunta2}></img>
        <div className="pregunta-icono-container">
            <h1 className="main-text bold-text">¿Cuál es tu género? 
            </h1>
            <div className="button-icono">
            {
                (iconMedia.matches) ?
                  <></>
                :
                <button 
                  className="icono-pregunta"
                  onClick={() => setButtonStatus(true)}
                >
                  <img src={iconoPregunta}></img>
                </button>
              }
            </div>
          </div>
          <motion.div
            initial={{opacity: 0, y: 30}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.7}}
          >
            <h3 className="secondary-text">Seleccciona</h3>
          </motion.div>
        </motion.div>
        <button
          onClick={() => lastPage()}
          className="flecha-atras-vector"
        >
          <img src={flechaAtras} />
        </button>

        {/* Botones */}
          <motion.div 
            className="gender-buttons-container"
            initial={{opacity: 0, y: 40}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.4}}
          >
           <button 
            className={`secondary-buttons ${generoSelection === 'Femenino' && 'bg-orange'}`}
            onClick={() => generoOnClick('Femenino')}
           >
              Femenino
           </button>
           <button 
            className={`secondary-buttons ${generoSelection === 'Masculino' && 'bg-orange'}`}
            onClick={() => generoOnClick('Masculino')}
           >
              Masculino
           </button>    
           <button 
            className={`secondary-buttons ${generoSelection === 'No binario' && 'bg-orange'}`}
            onClick={() => generoOnClick('No binario')}
           >
              No binario
           </button>
           <button 
            className="secondary-buttons"
            onClick={() => popupOtroGeneroValue(true)}
           >
              Otro
           </button>
           <button 
            className={`secondary-buttons ${generoSelection === 'Prefiero no decirlo' && 'bg-orange'}`}
            onClick={() => generoOnClick('Prefiero no decirlo')}
           >
              Prefiero no decirlo
           </button>
          </motion.div>
          {
            (genderSelectionWarn) &&
            <p className="warning-text secondary-text">Selecciona una respuesta para poder ayudarte mejor</p>
          }

        {/* Boton */}
        <div className="button-container">
          <img className="dot-vector" src={dot}></img>
          <button 
            className="main-button main-button-margin"
            className={`${ generoSelection ? 'main-button main-button-margin' : 'transparent-button main-button-margin'}`}
            onClick={ isGenero ? () => nextPage() : handleNextStep }
            >
            Continuar
          </button>
          <button
            className="popup-button"
            onClick={() => setPorqueButton(true)}
          >
            ¿Por Qué?
          </button>
        </div>
        { 
            (buttonStatus) &&
            <PopupScreen
              type='info' 
              title={"¿Qué es género?"} 
              content={"El concepto de género es una construcción social que permite comprender las diferencias sociales y culturales entre ser “mujeres” y “hombres”. Son parámetros de lo que un hombre y una mujer debe ser, hacer y parecer, definiéndolos como masculino y femenino, por oposición y con desigual valor."} 
              setPopup={setButtonStatus}
            /> 
          }
          { 
            (otroGeneroPopup) &&
            <PopupScreen
              type='form'
              title={"Género"} 
              // content={"El género se refiere a..."} 
              setPopup={setOtroGeneroPopup}
              popupUserData={userData}
              popupSetUserData={setUserData}
              popupSetIsGenero={setIsGenero}
              setNuevoGenero={setNuevoGenero}
              set0troGeneroPopup={setOtroGeneroPopup}
            /> 
          }
          {
            (porqueButton) &&
            <PopupScreen 
              title={"Preguntas acerca de ti"} 
              content={<>Conocer esta información me permitirá elaborar una<br></br>respuesta más adecuada para ti</>} 
              popupStatus={buttonStatus} 
              setPopup={setPorqueButton}
            /> 
          }
          {
            (nuevoGenero) &&
            <NuevoGeneroPage 
              genero={userData.genero}
              // genero={"nuevo"}
              nextPage={nextPage}
            />
          }
      </div>
    </div>
  )
}