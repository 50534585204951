/*
Componente    : Message.js
Ubicacion     : src/components/Messages/Message
Descripcion   : Componente que muestra un mensaje 
Argumentos    : user: Usuario
                generic: Seccion del json con las respuesta que retorna Watson
                  Puede tener diversos tipos de respuesta
                  text: Texto simple
                  image: Url de imagen
                  option: Opciones que realizan determinada accion
                    _btn
                      p.e: Boton Si en ¿Quieres ver nuestras preguntas frecuentes?
                      La accion que desencadena el boton se codifica en value y se procesa en accionaBoton()
                        p.e: abrirFaq
                    _btnLst
                      p.e: Preguntas relacionadas
                      El texto de la pregunta relacionada se envia al Socket directamente

Utilizado por : Messages.js
Notas         : Si es del mismo usuario muestra el mensaje con estilo diferente al del admin
*/
import React, { useContext, useEffect } from "react";
import "./Message.css";
import ReactEmoji from "react-emoji";
import { SocketContext } from "../../../context/SocketContext";

const Message = ({ message: { user, generic }, name }) => {
  const { socket, setMessages, messages } = useContext(SocketContext);
  let isSentByCurrentUser = false;

  const trimmedName = name.trim().toLowerCase();

  // Se usa para saber si es un mensaje del usuario o del admin
  if (user === trimmedName) {
    isSentByCurrentUser = true;
  }

  // Envia pregunta (relacionada)
  const enviaPregunta = (pregunta) => {
    socket.emit("sendMessage", pregunta, () => {});
  };

  const buttonUrlClick = (url) => {
    window.open(url);
  };

  useEffect(() => {
    if (generic) {
      generic.map((message) => {
        if (message.response_type === "pause") {
          setTimeout(() => {
            let tempMessages = messages.map((m) => {
              let g = m.generic.filter((e) => e.response_type !== "pause");
              m = { user: m.user, generic: g };
              return m;
            });
            setMessages(tempMessages);
          }, message.time);
        }
      });
    }
  }, []);

  return generic ? (
    isSentByCurrentUser ? (
      <div className="messageContainer justifyEnd">
        <div className="messageBox backgroundBlue message-box-adjust2">
          <p className="messageText colorWhite ">
            {ReactEmoji.emojify(generic[0].text)}
          </p>
        </div>
      </div>
    ) : (
      // Mensaje del admin, puede ser text, imagen, option (_btn, _btnLst)
      generic.map((elemento, i) =>
        elemento.response_type === "text" ? (
          <div key={i}>
            <div className="messageContainer justifyStart">
              <div className="messageBox backgroundLight message-box-adjust1">
                <p className="messageText colorDark">
                  {ReactEmoji.emojify(elemento.text)}
                </p>
              </div>
            </div>
          </div>
        ) : elemento.response_type === "image" ? (
          <div key={i}>
            <div className="messageContainer justifyStart">
              <div className="messageBox backgroundLight message-box-adjust1">
                <img src={elemento.source} className="messageImg" alt="icon" />
              </div>
            </div>
          </div>
        ) : elemento.response_type === "option" && elemento.title === "_btn" ? (
          elemento.options.map((option, i) => (
            <div key={i}>
              <div className="messageContainer justifyStart">
                <div className="messageBox backgroundLight message-box-adjust1 message-box-width">
                  <p className="messageText colorDark fq-question">
                    {ReactEmoji.emojify(elemento.description)}
                  </p>
                  <button className="buttonText">{option.label}</button>
                  {/* <button className="buttonText" onClick={() => buttonWebPage() }>youtube</button>  */}
                </div>
              </div>
            </div>
          ))
        ) : elemento.response_type === "option" &&
          elemento.title === "_btnLst" ? (
          <div>
            <p className="fq-text">Preguntas relacionadas a este tema -</p>
            {elemento.options.map((option, i) => (
              <div key={i}>
                <div className="messageContainer justifyStart">
                  <button
                    className="button messageBox backgroundOrange message-box-adjust1"
                    onClick={() => enviaPregunta(option.label)}
                  >
                    {option.label}
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : elemento.response_type === "option" &&
          elemento.title === "_btnUrl" ? (
          elemento.options.map((option, i) => (
            <div key={i}>
              <div className="messageContainer justifyStart">
                <div className="messageBox backgroundLight message-box-adjust1">
                  <p className="messageText colorDark">
                    {ReactEmoji.emojify(elemento.description)}
                  </p>
                  <button
                    className="buttonText"
                    onClick={() => buttonUrlClick(option.value.input.text)}
                  >
                    {option.label}
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : elemento.response_type === "pause" ? (
          <div key={i}>
            <div className="messageContainer justifyStart">
              <div className="messageBox backgroundLight message-box-adjust1">
                <p className="messageText colorDark">
                  {ReactEmoji.emojify("...")}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <> </>
        )
      )
    )
  ) : (
    <> </>
  );
};

export default Message;
