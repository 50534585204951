export const topics = [
  {
    id: 1,
    title: "Métodos anticonceptivos",
  },
  {
    id: 2,
    title: "Relaciones tóxicas",
  },
  {
    id: 3,
    title: "Primera vez",
  },
];

export const questions = [
  {
    topicId: 1,
    text: "¿Cómo usar un condón?",
  },
  {
    topicId: 1,
    text: "¿Dónde puedo comprar condones?",
  },
  {
    topicId: 1,
    text: "¿Cómo uso la pastilla anticonceptiva?",
  },
  {
    topicId: 2,
    text: "¿Cuando los celos son malos?",
  },
  {
    topicId: 2,
    text: "Consejos para superar a tu ex",
  },
  {
    topicId: 2,
    text: "¿Cómo saber si estoy en una relación tóxica?",
  },
  {
    topicId: 3,
    text: "¿La primera vez duele y/o hay sangrado?",
  },
  {
    topicId: 3,
    text: "¿Qué me recomiendan hacer en mi primera vez?",
  },
  {
    topicId: 3,
    text: "¿Cómo me protejo en mi primera vez?",
  },
];
