import React, { useEffect, useRef, useState } from 'react'
import './Intro.css'
import star from "../../assets/imgs/star.svg";
// import dotsIntro from "../../assets/imgs/dots-intro.svg";
import dot from "../../assets/imgs/dot.svg";
// import dspLogo from "../../assets/imgs/dspLogo.svg";
import starRotated from "../../assets/imgs/star-rotated.svg";
import '@lottiefiles/lottie-player';
// import {create} from '@lottiefiles/lottie-interactivity';
import Lottie from "react-lottie";
import dspLottie from "../../assets/Lotties/DSP.json";
import puntos from "../../assets/Lotties/puntos.json";

export const IntroImg = ({nextPage}) => {

  useEffect(() => {
    const timer = setTimeout(() => {
        nextPage();
    }, 3500);
  
    return () => {
      clearTimeout(timer);
    }
  }, [])

  const logoDefaultOptions = {
    loop: false,
    autoplay: true, 
    animationData: dspLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const puntosDefaultOptions = {
    loop: false,
    autoplay: true, 
    animationData: puntos,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  
    return (
        <>
        <div className="container">
          {/* <img className="ondas-vector" src={ondas}></img> */}
          <div className="main-content intro-center">
            <img className="star-vector" src={star} />
            <img className="star-rotated-vector" src={starRotated}></img>
            <div className="img-container">
              <Lottie 
                options={logoDefaultOptions}
                height={'100%'}
                width={'100%'}
              />
              <img className="dot-vector-intro dot-vector-adjust" src={dot}></img>
              <div
                className="dots-vector-intro"
              >               
              <Lottie
                options={puntosDefaultOptions}
                width={'100px'}
              />
              </div>
              {/* <img className="dots-vector-intro" src={dotsIntro}></img> */}
            </div>
          </div>
        </div>
        </>
    )
}

