import React, { useEffect, useRef, useState } from 'react'
import './Intro.css'
import star from "../../assets/imgs/star.svg";
import starRotated from "../../assets/imgs/star-rotated.svg";
import dot from "../../assets/imgs/dot.svg";
import textLine from "../../assets/imgs/textLine.svg";
import textLineResponsive from "../../assets/imgs/textLineResponsive.svg";
import { motion } from "framer-motion";
import '@lottiefiles/lottie-player';

import Lottie from "react-lottie";
import paltita from "../../assets/Lotties/paltita.json";

export const ContinueChat = ({nextPage, text = ""}) => {

  const [time, setTime] = useState(false)

  const handleNextStep = () => {
    nextPage();
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(true)
    }, 1200);
  
    return () => {

      clearTimeout(timer);
    }
  }, [])

  const paltitaDefaultOptions = {
    loop: false,
    autoplay: true, 
    animationData: paltita,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const textLineMedia = window.matchMedia("(min-width: 0px) and (max-width: 480px)");

    return (
      <>

            <div className="container">
              {/* <img className="ondas-vector" src={ondas}></img> */}
            <div className="main-content">
              <img className="star-vector" src={star} />
              <img img className="star-rotated-vector" src={starRotated}></img>
    
            {/* Titulo */}
            {
              time &&
              ( 
              <motion.div 
                className="text-container"
                initial={{opacity: 0, y: 30}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
              >
                <p className="main-text">{text}</p>
                {
                  (textLineMedia.matches) ? 
                  <motion.img className="text-line" src={textLineResponsive}></motion.img>
                  :
                  <motion.img className="text-line" src={textLine}></motion.img>
                }
              </motion.div>
              )
            }

            {/* Imagen */}
            <div 
              className={`${time ? "img-container" : "center-img" }`}
              // className="center-img"
            >
              <Lottie
                options={paltitaDefaultOptions}
                height={300}
                width={300}
              />
            </div>
    
            {/* Boton */}
              
              {
                time &&
                (
                <div className="button-container">
                <img className="dot-vector" src={dot}></img>
                <motion.button
                  initial={{
                    width: 0, 
                    height: 0, 
                    opacity: 0}}
                  animate={{
                    type: "spring",
                    stiffness: 100,
                    width: "300%", 
                    height: "70px",
                    opacity: 1}}
                  transition={{duration: 0.8}}
                  className="main-button"
                  onClick={handleNextStep}
                >
                    Continuar
                </motion.button>
                </div>
                )
              }
          </div>
        </div>
        </>
    )
}
