import { useCallback, useEffect, useState } from "react";
import io from "socket.io-client";
// import randomId from 'random-id';

export const useSocket = (serverPath) => {
  const [socket, setSocket] = useState(null);
  const [online, setOnline] = useState(false);
  const [messages, setMessages] = useState([{ user: "", generic: [] }]);

  // Conecta al socket
  const conectarSocket = useCallback(() => {
    const token = localStorage.getItem("token");

    const socketTemp = io.connect(serverPath, {
      transports: ["websocket"],
      autoConnect: true,
      forceNew: true,
      query: {
        "x-token": token,
      },
    });
    setSocket(socketTemp);
  }, [serverPath]);

  // Desconecta del socket
  const desconectarSocket = useCallback(() => {
    if (socket) {
      socket.disconnect();
    }
  }, [socket]);

  // cambia propiedad onLine cuando varia el estado del Socket
  useEffect(() => {
    setOnline(socket?.connected);
  }, [socket]);

  // Cuando el stocket esta activo se abre canal de comunicacion connect
  useEffect(() => {
    if (socket) {
      socket.on("connect", () => setOnline(true));
    }
  }, [socket]);

  // Cuando el stocket esta activo se abre canal de comunicacion disconnect
  useEffect(() => {
    if (socket) {
      socket.on("disconnect", () => setOnline(false));
    }
  }, [socket]);

  // Abre medio de comunicacion para que el servidor envie mensajes al cliente
  useEffect(() => {
    if (socket) {
      socket.on("message", async (message) => {
        let tiempo = 0;
        let message1 = [];
        let message2 = [];
        let isPause = false;

        message.generic.map((m) => {
          if (m.response_type === "pause") {
            tiempo = m.time;
          }
        });

        message.generic.map((m) => {
          if (m.response_type !== "pause") {
            if (!isPause) {
              message1.push(m);
            } else {
              message2.push(m);
            }
          } else {
            // message1.push(m);
            message1.push(m);
            isPause = true;
          }
        });

        message1[0] &&
          setMessages((messages) => [
            ...messages,
            { user: message.user, generic: message1 },
          ]);

        setTimeout(() => {
          message2[0] &&
            setMessages((messages) => [
              ...messages,
              { user: message.user, generic: message2 },
            ]);
        }, tiempo * 1.05);
      });
    }
  }, [socket]);

  return {
    socket,
    online,
    conectarSocket,
    desconectarSocket,
    messages,
    setMessages,
  };
};
