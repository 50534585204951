import React from "react";
import star from "../../assets/imgs/star.svg";
import starRotated from "../../assets/imgs/star-rotated.svg";

import "./styles.css";
import "animate.css";

const Terms = ({ setTerminos }) => {
  return (
    <div className="container">
      <div className="container animate__animated animate__slideInUp">
        <img className="star-vector" src={star} alt="icon" />
        <img className="star-rotated-vector" src={starRotated} alt="icon" />
        <button className="mi-salir-button" onClick={() => setTerminos(false)}>
          Cerrar
        </button>
        <div className="terminos-container">
          <h1>Términos y Condiciones</h1>
          <p className="terminos-text">
            Aquí están los detalles esenciales. Información personal La
            plataforma Dilo Sin Paltas ayuda a resolver preguntas personales, y
            nunca necesitará acceso a su información personal. En este sentido,
            no ingrese su nombre completo, correo electrónico, documento de
            identidad, tarjeta de crédito, dirección postal, entre otros.
            <br></br>
            <br></br>
            Política de privacidad Hablemos Sin Tabúes (colectivamente, “Sin
            Tabúes”) respeta y se compromete a velar por la privacidad de los
            usuarios de nuestros sitios web, y servicios en línea y electrónicos
            en general. Por eso, los datos ingresados, como edad, sexo y género,
            son tratados con la responsabilidad debida y manteniendo el
            anonimato de cada persona. Asimismo, tenga en cuenta que Sin Tabúes
            puede recopilar, utilizar y compartir información sobre los datos
            recopilados en esta página web en línea a como se establece en esta
            Política de privacidad.
            <br></br>
            <br></br>
            En resumen, la Política de privacidad que maneja Sin Tabúes se
            resumen en los siguiente puntos: (1) uso de su información, (2)
            quiénes tienen acceso a la divulgación de su información, (3) cómo
            se recopila información de usted o sobre usted mediante Internet,
            (4) opciones que posee con respecto a la información que
            recopilamos, (5) forma de contactarnos. De este modo, lo alentamos a
            leer pausadamente nuestra Política de privacidad completa para
            entender cómo será manejada la información brindada por usted.
            <br></br>
            <br></br>
            Aspectos destacados de la política de privacidad
            <br></br>
            <br></br>
            Cómo usamos su información Nosotros podemos usar la información que
            recopilemos de usted o sobre usted a través de los Servicios en
            línea para una serie de propósitos, que incluye, entre otros:
            <ul>
              <li>
                identificar estadísticamente la frecuencia de ciertos tipos de
                preguntas;
              </li>
              <li>
                hacer un seguimiento de las preguntas que no cuenten con una
                respuesta automática;
              </li>
              <li>realizar investigaciones;</li>
              <li>proporcionarle información específica, clara y certera.</li>
            </ul>
            También podemos combinar o agregar cualquier información que
            recopilamos a través de los Servicios en línea o en cualquier otro
            lugar para cualquiera de estos fines, incluida la entrega de
            Anuncios dirigidos.
            <br></br>
            <br></br>A quién divulgamos su información brindada anónimamente
            <br></br>
            <br></br>
            Podemos divulgar la información que recopilemos de usted o sobre
            usted a través de los Servicios en línea:
            <ul>
              <li>
                a otras organizaciones que trabajan con nosotros para
                implementar estrategias públicas o de acción para contribuir a
                la difusión de la Educación Sexual Integral;
              </li>
              <li>
                a investigadores que necesiten recurrir a la información
                anonimizada sobre los datos (edad, sexo y género) con el fin de
                elaborar informes.
              </li>
            </ul>
            Además, podemos compartir información anonimizada, como informes
            sobre datos demográficos de usuarios y patrones de tráfico, con
            terceros, incluidos los investigadores.
            <br></br>
            <br></br>
            Cómo recolectamos su información Sin Tabúes y nuestros proveedores
            de servicios recolectan la información que proporciona cuando
            utiliza nuestros servicios, incluyendo cuando:
            <ul>
              <li>realiza consultas en la plataforma Dilo Sin Paltas;</li>
              <li>responde nuestras preguntas;</li>
              <li>contribuye con contenido a nuestros servicios en línea;</li>
              <li>firma una petición en línea;</li>
              <li>postula a un voluntariado o un trabajo;</li>
              <li>utiliza nuestro contenido interactivo;</li>
              <li>participa en nuestras encuestas;</li>
              <li>
                participa en concursos, promociones y campañas promocionadas en
                la plataforma Dilo Sin Paltas;
              </li>
              <li>
                se registra para recibir actualizaciones y notificaciones de la
                plataforma Dilo Sin Paltas;
              </li>
              <li>
                nos contacta para resolver una pregunta o petición o nos deja
                algún mensaje.
              </li>
            </ul>
            La información que nos proporciona puede incluir, pero no se limita
            a:
            <ul>
              <li>su edad;</li>
              <li>su sexo</li>
              <li>su género</li>
              <li>
                información que nos proporciona cuando postula a un empleo o
                voluntariado
              </li>
              <li>
                información que nos proporciona cuando pide información o nos
                contacta
              </li>
            </ul>
            Cuando utiliza nuestros servicios en línea, Sin Tabúes y los
            proveedores de servicio podemos recopilar automáticamente cierta
            información técnica de su computadora o dispositivo móvil a lo largo
            del tiempo y en diferentes sitios web utilizando cookies, como su
            dirección de Protocolo de Internet, su ubicación geográfica, tipo de
            navegador, su sistema operativo, las páginas de servicios en línea
            que visita, las páginas que visita inmediatamente antes y después de
            acceder a nuestros servicios en línea y los términos que ingresa en
            los servicios en línea que visita. Esta información nos permite
            reconocerle y brindarle una experiencia personalizada cuando vuelva
            a acceder a nuestro servicio, mejorar nuestros servicios y mostrarle
            publicidad personalizada de acuerdo a sus intereses.
            <br></br>
            <br></br>
            Tus opciones Si no desea que la página Dilo Sin Paltas admita el uso
            de cookies durante su experiencia, puede configurar su navegador web
            para que los rechace.
            <br></br>
            <br></br>
            Si desea revisar o corregir la información de contacto que tenemos
            archivada para usted o informarnos cómo desea que se use su
            información de contacto, comuníquese con nuestro personal utilizando
            la información de contacto a continuación.
            <br></br>
            <br></br>
            Cómo contactarnos Si tiene alguna duda sobre nuestra Política de
            Privacidad o sobre el tratamiento de su información personal, por
            favor contáctenos en:
            <br></br>
            <br></br>
            Agrup. Pachacamac 3ra Et. Sect. 3 Lima, Villa El Salvador
            sintabues.org@gmail.com 946253327
            <br></br>
            <br></br>
            Política de privacidad completa Esta política de privacidad describe
            cómo Sin Tabúes recolecta y usa su información en los servicios en
            línea que brinda. Al usar nuestros servicios, nos otorga su
            consentimiento para procesar su información de la forma descrita, la
            que podremos modificar en cualquier momento.
            <br></br>
            <br></br>
            ¿Cómo usamos su información? Recopilamos la información de sus
            consultas a través de nuestra página web para diferentes fines los
            cuales incluyen, entre otros:
            <ul>
              <li>
                generar investigaciones académicas que demuestran
                estadísticamente algún tema tratado sobre la educación sexual
                integral y sus vertientes;
              </li>
              <li>
                conocer los patrones que las preguntas más frecuentes siguen;
              </li>
              <li>
                promover la difusión de la Educación Sexual Integral en base a
                las preguntas que reciba la plataforma.
              </li>
            </ul>
            Los Servicios en línea también pueden contener enlaces a sitios web
            y aplicaciones de terceros para su conveniencia e información. Sin
            Tabúes no controla esos sitios web y aplicaciones de terceros o sus
            prácticas de privacidad, que pueden diferir de las nuestras, y no
            respaldamos ni hacemos ninguna declaración sobre esos sitios web y
            aplicaciones de terceros. Usted reconoce y acepta que no somos
            responsables de la recopilación y el uso de su información por parte
            de sitios web y aplicaciones de terceros que no están bajo nuestro
            control, y dicha información no se rige por esta Política de
            privacidad. Recuerde que no podemos proteger su privacidad una vez
            que abandone los Servicios en línea, por lo que cuando visite sitios
            web y aplicaciones de terceros, lo alentamos a que revise sus
            políticas de privacidad y tenga cuidado con la información que envía
            o comparte.
            <br></br>
            <br></br>
            ¿Qué información recolectamos? Recolectamos la información que nos
            proporcionas cuando utiliza nuestros servicios en línea y cuando:
            <ul>
              <li>realiza consultas en la plataforma Dilo Sin Paltas;</li>
              <li>responde nuestras preguntas;</li>
              <li>contribuye con contenido a nuestros servicios en línea;</li>
              <li>firma una petición en línea;</li>
              <li>postula a un voluntariado o un trabajo;</li>
              <li>utiliza nuestro contenido interactivo;</li>
              <li>participa en nuestras encuestas;</li>
              <li>
                participa en concursos, promociones y campañas promocionadas en
                la plataforma Dilo Sin Paltas;
              </li>
              <li>
                se registra para recibir actualizaciones y notificaciones de la
                plataforma Dilo Sin Paltas;
              </li>
              <li>
                nos contacta para resolver una pregunta o petición o nos deja
                algún mensaje.
              </li>
            </ul>
            La información que nos proporciona puede incluir, entre otras:
            <ul>
              <li>su edad</li>
              <li>su sexo</li>
              <li>su género</li>
              <li>
                información que nos proporciona cuando postula a un empleo o
                voluntariado;
              </li>
              <li>
                información que nos proporciona cuando pide información o nos
                contacta.
              </li>
            </ul>
            Le recordamos que usted tiene el control de su privacidad, por lo
            que le recomendamos pensar cuidadosamente antes de proporcionar
            cualquier información personal o financiera en línea.
            <br></br>
            <br></br>
            Información que recolectamos automáticamente Cuando utiliza nuestros
            servicios en línea, Sin Tabúes y los proveedores de servicio podrán
            recolectar información acerca de su computadora o dispositivo móvil
            como su dirección de Protocolo de Internet, su ubicación geográfica,
            tipo de navegador, su sistema operativo, las páginas de servicios en
            línea que visita, las páginas que visita inmediatamente antes y
            después de acceder a nuestros servicios en línea y los términos que
            ingresa en los servicios en línea que visita. Esta información nos
            permite reconocerle y brindarle publicidad personalizada.
            Recolectamos esta información utilizando “cookies”, que son pequeñas
            filas de texto que se guardan en su ordenador cuando visita y
            utiliza determinados sitios web. También recolectamos esta
            información configurando un identificador anónimo único llamado User
            ID, establecido por Universal Google Analytics. La identificación de
            usuario permite identificar usuarios únicos en múltiples
            dispositivos y navegadores. Borrar sus cookies no garantiza que no
            será reconocido como el mismo visitante incluso después de
            borrarlas. Los visitantes del sitio web que no desean que sus datos
            se recopilen con Google Analytics pueden instalar el complemento de
            inhabilitación para navegadores de Google Analytics.
            <br></br>
            <br></br>
            Si se encuentra en algún país de la Unión Europea y accede a nuestro
            sitio web, tenga en cuenta que este sitio web y la información
            mostrada no se dirige exclusivamente a usted.
            <br></br>
            <br></br>
            Anuncios en línea Nuestros socios publicitarios pueden usar cookies
            y tecnologías similares en los servicios en línea y en sitios web de
            terceros en los que aparecen nuestros anuncios, lo que les permite
            rastrear cierta información sobre el comportamiento del usuario,
            como "datos de flujo de clics". Las prácticas de información de
            estos socios publicitarios se rigen por sus propias políticas de
            privacidad y no por esta Política de Privacidad.
            <br></br>
            <br></br>
            ¿Cómo aseguramos su información? Nos esforzamos por utilizar medidas
            de seguridad administrativas, técnicas y físicas que nos permitan
            proteger la información recopilada a través de los servicios en
            línea. Sin embargo, ningún sistema de información puede ser 100%
            seguro, por lo que no podemos garantizar la seguridad absoluta de su
            información. Tampoco somos responsables de la seguridad de la
            información que se transmite a los servicios en línea a través de
            redes que no controlamos, incluidas Internet y las redes
            inalámbricas. Si le preocupa la seguridad de tu información
            confidencial, no la envíe electrónicamente.
            <br></br>
            <br></br>
            Cambios en esta Política de Privacidad Si decidimos actualizar
            nuestra Política de Privacidad, le notificaremos publicando una
            nueva Política de Privacidad en la página web. Si estas
            modificaciones cambian significativamente el modo en que usamos su
            información, le solicitaremos su consentimiento antes de
            implementarlas.
            <br></br>
            <br></br>
            Tus opciones Si no desea que la página Dilo Sin Paltas admita el uso
            de cookies durante su experiencia, puede configurar su navegador web
            para que los rechace.
            <br></br>
            <br></br>
            Si desea revisar o corregir la información de contacto que tenemos
            archivada para usted o informarnos cómo desea que se use su
            información de contacto, comuníquese con nuestro personal utilizando
            la información de contacto a continuación.
            <br></br>
            <br></br>
            Para darse de baja de Google Analytics para la web, visite la página
            de exclusión de Google Analytics e instale el complemento para su
            navegador.
            <br></br>
            <br></br>
            Contáctenos Si tiene alguna duda sobre nuestra Política de
            Privacidad o sobre el tratamiento de su información personal, por
            favor contáctenos en:
            <br></br>
            <br></br>
            Agrup. Pachacamac 3ra Et. Sect. 3 Lima, Villa El Salvador
            sintabues.org@gmail.com 946253327
            <br></br>
            <br></br>
            Podrá mantener el anonimato al contactarnos o enviarnos contenido a
            través de nuestros servicios en línea, por lo que no es necesario
            que nos provea su información personal o la de terceros. Si desea
            proteger completamente su privacidad, le recomendamos que no
            proporcione ninguna información que podría facilitar su
            identificación o la de terceros.
            <br></br>
            <br></br>
            Esta Política de Privacidad fue actualizada en julio de 2022.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
