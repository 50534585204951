import React, { useContext, useEffect } from "react";
import { createContext } from "react";
import { AuthContext } from "./AuthContext";
import { useSocket } from "../hooks/useSocket";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const socketUrl = "https://chatbot.dilosinpaltas.com";

  const {
    socket,
    online,
    conectarSocket,
    desconectarSocket,
    messages,
    setMessages,
  } = useSocket(socketUrl); //'http://localhost:3101'
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    if (auth.uid) {
      conectarSocket();
    }
  }, [auth, conectarSocket]);

  useEffect(() => {
    if (!auth.uid) {
      desconectarSocket();
    }
  }, [auth, desconectarSocket]);

  return (
    <SocketContext.Provider value={{ socket, online, messages, setMessages }}>
      {children}
    </SocketContext.Provider>
  );
};
